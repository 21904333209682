import styles from "../style.module.scss";
import { AdsContext } from "../AdsContentPage";
import { useContext } from "react";

const AdsPreview = ({ data }) => {
  const { previewData } = useContext(AdsContext);

  return (
    <div
      className={styles["ads-card"]}
      style={{
        backgroundImage: `url(${previewData.previewBg})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={styles["ads-card-logo"]}>
        <img src={previewData.previewLogo} alt="logo" />
      </div>
      <h2>{previewData.title}</h2>
      <p>{previewData.description}</p>
    </div>
  );
};

export default AdsPreview;
