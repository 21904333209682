import { useState } from "react";
import styles from "../../style.module.scss";
import { useTranslation } from "react-i18next";
import AdsUpload from "../AdsUpload";
import AdsForm from "../AdsForm";
import { DatePicker, Form, Input, Select } from "antd";
import AdsPreview from "../AdsPreview";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";

const SplashScreenAdPage = ({ form, loading }) => {
  const { t } = useTranslation();

  const TexArea = Input.TextArea;
  const RangePicker = DatePicker.RangePicker;

  return (
    <div className={styles["ads-page-wrapper"]}>
      <AdsForm form={form}>
        <div className={styles["ads-collection-head-wrapper"]}>
          <AdsUpload />
          <div className={styles["ads-page-collection-head-data"]}>
            <Form.Item
              label={t("title")}
              name="title"
              rules={[
                {
                  required: true,
                  message: t("enter_title"),
                },
              ]}
            >
              <Input placeholder={t("enter_title")} />
            </Form.Item>
            <Form.Item
              label={t("description")}
              name="description"
              rules={[
                {
                  required: true,
                  message: t("enter_description"),
                },
              ]}
            >
              <TexArea placeholder={t("enter_description")} />
            </Form.Item>
          </div>
        </div>

        <div className={styles["ads-page-collection-info-data"]}>
          <Form.Item
            label={t("conversation_link")}
            name="conversion_url"
            rules={[
              {
                type: "url",
                message: t("invalid_url"),
              },
              {
                required: true,
                message: t("enter_conversation_link"),
              },
            ]}
          >
            <Input placeholder={t("enter_conversation_link")} />
          </Form.Item>
        </div>
        <div className={styles["ads-button"]}>
          <ButtonComponent
            title={t("save")}
            icon={icons.CHECK}
            onClick={() => form.submit()}
            isLoading={loading}
          />
        </div>
      </AdsForm>
      <AdsPreview />
    </div>
  );
};

export default SplashScreenAdPage;
