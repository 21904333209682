import adsInstance from "../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  GETTER: `${host}admin/ads/getter`,
  ADS: `${host}admin/ads/`,
};

const customAdsApi = {
  GETTER: (query) => {
    return adsInstance.get(URL.GETTER, { params: query });
  },

  DELETE: (id) => {
    return adsInstance.delete(`${URL.ADS}many`, { data: { ids: id } });
  },
};

export default customAdsApi;
