import styles from "../style.module.scss";
import { Form } from "antd";
import { AdsContext } from "../AdsContentPage";
import { useContext } from "react";

export const AdsForm = ({ children, form }) => {
  const { onSave } = useContext(AdsContext);

  const handleFinish = (values) => {
    onSave(values);
  };

  return (
    <div className={styles["ads-page-form"]}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        {children}
      </Form>
    </div>
  );
};

export default AdsForm;
