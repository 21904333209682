import "./app.scss";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import "./App.css";
import "./Util.css";

import "./Antd.scss";

import { InoToastContainer } from "./components/toast/Toast";

import "filepond/dist/filepond.min.css";

import * as actions from "./redux-store/actions";

import ContentProviderPage from "./routes/ContentProviderPage";
import { host } from "./server/url";
import { ConfigProvider, notification } from "antd";
import LoginPage from "./pages/login/LoginPage";
import LayoutComponent from "./layout/LayoutComponent";
import DashboardContent from "./routes/dashboard/DashboardContent";
import PATHS from "./config/paths";
import ChatPage from "./routes/chat/ChatPage";
// import EmailNotifications from "./routes/email-notifications/EmailNotifications";
import UserIconsPage from "./pages/application/user-icons/UserIconsPage";
import MediaPage from "./routes/vod/medias/media/MediaPage";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ContentProviderLogin from "./pages/content-provider/ContentProviderLogin";
import GeneralSettings from "./pages/settings/general-settings/GeneralSettingsPage";
import WelcomePage from "./pages/welcome/WelcomePage";
import NewsPage from "./pages/web-page/news/NewsPage";
import FaqPage from "./pages/web-page/faq/FaqPage";
import FeaturesPage from "./pages/web-page/features/FeaturesPage";
import WebSettingsPage from "./pages/web-page/settings/WebSettingsPage";
import WebSliderPage from "./pages/web-page/slider/WebSliderPage";
import PlatformsPage from "./pages/web-page/platforms/PlatformsPage";
import LauncherDevicesPage from "./pages/launcher/devices/LauncherDevicesPage";
import ServiceNotificationsPage from "./pages/settings/service-notifications/ServiceNotificationsPage";
import LiveTvCategoriesPage from "./pages/live-tv/categories/LiveTvCategoriesPage";
import LiveTvPage from "./pages/live-tv/live-tv/LiveTvPage";
import TvodPaymentPage from "./pages/payments/tvod-payment/TvodPaymentPage";
import PaymentHistoryPage from "./pages/payments/history/PaymentHistoryPage";
import PaymentConfigPage from "./pages/payments/config/PaymentConfigPage";
import PaymentsSubscriptionsPage from "./pages/payments/subscriptions/PaymentsSubscriptionsPage";
import HistoryPage from "./pages/history/HistoryPage";
import WebHooksPage from "./pages/settings/web-hooks/WebHooksPage";
import EpgPage from "./pages/epg/EpgPage";
import AdminsPage from "./pages/admins/AdminsPage";

import "react-color-palette/lib/css/styles.css";
import ApksPage from "./pages/settings/apks/ApksPage";
import UsersGroupsPage from "./pages/users/groups/UsersGroupsPage";
import UsersPage from "./pages/users/users/UsersPage";
import UserPage from "./pages/users/user/UserPage";
import VersionsPage from "./pages/settings/versions/VersionsPage";
import CdnPage from "./pages/settings/cdn/CdnPage";
import StoreLinksPage from "./pages/settings/store-links/StoreLinksPage";
import ImportPage from "./pages/import/ImportPage";
import AdsPage from "./pages/ads/AdsPage";
import TranscodersGroupsPage from "./pages/transcoders/groups/TranscodersGroupsPage";
import ResolutionsPage from "./pages/transcoders/resolutions/ResolutionsPage";
import LauncherConfigsPage from "./pages/launcher/configs/LauncherConfigsPage";
import LauncherAppsPage from "./pages/launcher/apps/LauncherAppsPage";
import LauncherMessagesPage from "./pages/launcher/messages/LauncherMessagesPage";
import VodTranscodingPage from "./pages/vod/transcoding/VodTranscodingPage";
import LessonsPage from "./pages/lessons/lessons/LessonsPage";
import QuizPage from "./pages/lessons/quiz/QuizPage";
import VodGenresPage from "./pages/vod/genres/VodGenresPage";
import VodContentProvidersPage from "./pages/vod/content-providers/VodContentProvidersPage";
import CatchUpRecordingsPage from "./pages/catch-up/recordings/CatchUpRecordingsPage";
import CatchUpServersPage from "./pages/catch-up/servers/CatchUpServersPage";
import StreamerServersPage from "./pages/streamer/servers/StreamerServersPage";
import StreamerStreamsPage from "./pages/streamer/streams/StreamerStreamsPage";
import BasicInfoPage from "./pages/application/basic-info/BasicInfoPage";
import LanguagesPage from "./pages/application/languages/LanguagesPage";
import TranslationsPage from "./pages/application/translations/TranslationsPage";
import TranscodersServersPage from "./pages/transcoders/servers/TranscodersServersPage";
import VodCollectionsPage from "./pages/vod/collections/VodCollectionsPage";
import CastPage from "./pages/vod/cast/CastPage";
import TranscoderTranscodingPage from "./pages/transcoders/transcoding/TranscoderTranscodingPage";
import ErrorPage from "./pages/error/ErrorPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import MediasPage from "./pages/vod/medias/MediasPage";
import MediaContentPage from "./pages/vod/media-content/MediaContentPage";
import AvailableDevicesPage from "./pages/web-page/available-devices/AvailableDevicesPage";
// import DocumentsPage from "./pages/settings/documents/DocumentsPage";
import CollectionPage from "./pages/vod/collection/CollectionPage";
import AppBrandingPage from "./pages/application/branding/AppBrandingPage";
import SmtpConfigPAge from "./pages/settings/smtp-config/SmtpConfigoage";
import PricingLayout from "./layout/PricingLayout";
import PricingPage from "./pages/pricing/PricingPage";
import BillingStatusPage from "./pages/pricing/components/billing-status/BillingStatusPage";
import SocialMediaAuth from "./pages/settings/social-media/SocialMEdiaAuth";
import UsageInfoPage from "./pages/usage-info/UsageInfoPage";
import MenuConfigPage from "./pages/application/menu-config/MenuConfigPage";
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage";
import PrivacyPage from "./pages/privacy-policy/PrivacyPolicy";
import AppRadiusesPage from "./pages/application/app-radiuses/AppRadiusesPage";
import SeriesPage from "./pages/vod/medias/SeriasPage";
import PromoCodesPage from "./pages/promo-codes/PromoCodesPage";
import ClientPage from "./pages/custom-ads/client/ClientPage";
import CustomAdFilterPage from "./pages/custom-ads/filter/FilterPage";
import AdsPricingPage from "./pages/custom-ads/pricing/AdsPricingPage";
import CampaignsPage from "./pages/custom-ads/campaigns/CampaignsPage";
import CustomAdsPage from "./pages/custom-ads/custom-ads-page/CustomAdsPage";
import AdsContentPage from "./pages/custom-ads/ads-content-page/AdsContentPage";

export let socket = null;

const token = localStorage.getItem("admtoken");

export default function App({ toggleWorker }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pwa, setPwa] = useState(false);

  const { dashboardSettings, permissions } = useSelector((state) => state.globalState);

  const customAddsPermission = true;

  const { setNewMessage, setIsChangedNotReadMessagesCount } = bindActionCreators(
    actions,
    dispatch
  );

  const connectSocket = () => {
    if (token) {
      socket = window.io(host, {
        auth: {
          authorization: token,
        },
      });

      socket.on("connect", (data) => {
        console.log("socket connected");
      });

      socket.on("connect_error", (err) => {
        console.log("socket connection error");
      });

      socket.on("new_chat_message", (data) => {
        setNewMessage(data);
        setIsChangedNotReadMessagesCount(new Date());
        if (window.location.hash === "#/dashboard/messages") {
          return;
        }

        notification.success({
          message: "New message",
          description: data.content,
          style: { cursor: "pointer" },
          onClick: () => {
            navigate(PATHS.MESSAGES, { state: { id: data.conversation_id } });
          },
        });
      });
    }
  };

  useEffect(() => {
    connectSocket();
    toggleWorker(pwa);

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594",
          },

          Pagination: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594",
          },

          Select: {
            colorPrimaryHover: dashboardSettings?.primaryColor || "#43b594",
          },

          Input: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594",
          },
        },
      }}
    >
      <InoToastContainer />

      <Routes>
        <Route path="/" index element={<WelcomePage />} />
        <Route path={PATHS.LOGIN} element={<LoginPage />} />
        <Route path={PATHS.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPage />} />
        <Route path={PATHS.CONTENT_PROVIDER_LOGIN} element={<ContentProviderLogin />} />
        <Route path={PATHS.CONTENT_PROVIDER_PROFILE} element={<ContentProviderPage />} />
        {/* {!permissions?.cloud_service && ( */}
        <Route path={PATHS.CLOUD} element={<PricingLayout />}>
          // <Route path={`${PATHS.CLOUD_PRICING}`} element={<PricingPage />} />
        </Route>
        {/* // )} */}
        {/* <Route path={`${PATHS.CLOUD_PRICING}`} element={<PricingPage />} /> */}
        <Route path={PATHS.BILLING_STATUS} element={<BillingStatusPage />} />
        <Route path={PATHS.DASHBOARD} element={<LayoutComponent />}>
          <Route path={PATHS.DASHBOARD_CONTENT} element={<DashboardPage />} />

          {/* CHANNELS */}
          {permissions?.LIVE_TV && (
            <>
              {permissions?.EPG && <Route path={PATHS.EPG} element={<EpgPage />} />}
              <Route path={PATHS.LIVE_TV_CATEGORIES} element={<LiveTvCategoriesPage />} />
              <Route path={PATHS.LIVE_TV} element={<LiveTvPage />} />
            </>
          )}

          {permissions?.chat_message && (
            <Route path={PATHS.MESSAGES} element={<ChatPage />} />
          )}

          {/* Lessons */}
          {permissions?.lessons ||
            (permissions?.quiz && (
              <>
                <Route path={PATHS.QUIZ} element={<QuizPage />} />
                <Route path={PATHS.LESSONS} element={<LessonsPage />} />
              </>
            ))}

          {/* USERS */}
          <Route path={PATHS.USERS} element={<UsersPage />} />
          <Route path={`${PATHS.USER}:id`} element={<UserPage />} />
          <Route path={PATHS.USERS_GROUP} element={<UsersGroupsPage />} />

          {/* PAYMENTS */}
          <Route path={PATHS.TVOD_PAYMENT} element={<TvodPaymentPage />} />
          <Route path={PATHS.PAYMENT_HISTORY} element={<PaymentHistoryPage />} />
          <Route
            path={PATHS.PAYMENT_SUBSCRIPTION}
            element={<PaymentsSubscriptionsPage />}
          />
          <Route path={PATHS.PAYMENT_CONFIGS} element={<PaymentConfigPage />} />

          {/* <Route path={PATHS.EMAIL_NOTIFICATION} element={<EmailNotifications />} /> */}

          <Route path={PATHS.ADMIN} element={<AdminsPage />} />
          {permissions?.Ads && <Route path={PATHS.ADS} element={<AdsPage />} />}

          {/* ADS */}
          {/* {permissions?.Ads && <Route path={PATHS.ADS} element={<AdsPage />} />} */}
          {customAddsPermission && (
            <>
              <Route path={PATHS.CUSTOM_ADS} element={<CustomAdsPage />} />
              <Route path={PATHS.CUSTOM_ADS_CLIENT} element={<ClientPage />} />
              <Route path={PATHS.CUSTOM_ADS_FILTER} element={<CustomAdFilterPage />} />
              <Route path={PATHS.CUSTOM_ADS_PRICING} element={<AdsPricingPage />} />
              <Route path={PATHS.CUSTOM_ADS_CAMPAIGNS} element={<CampaignsPage />} />
              <Route path={`${PATHS.CUSTOM_ADS_CONTENT}`} element={<AdsContentPage />} />
              <Route
                path={`${PATHS.CUSTOM_AD_CONTENT}:id`}
                element={<AdsContentPage />}
              />
            </>
          )}

          {/* LAUNCHER */}
          {permissions?.launcher && (
            <>
              <Route path={PATHS.LAUNCHER_APPS} element={<LauncherAppsPage />} />
              <Route path={PATHS.LAUNCHER_MESSAGES} element={<LauncherMessagesPage />} />
              <Route path={PATHS.LAUNCHER_CONFIGS} element={<LauncherConfigsPage />} />
              <Route path={PATHS.LAUNCHER_DEVICES} element={<LauncherDevicesPage />} />
            </>
          )}

          {/* WEB PAGE */}

          {permissions?.web_page && (
            <>
              <Route path={PATHS.WEB_NEWS} element={<NewsPage />} />
              <Route path={PATHS.WEB_FAQ} element={<FaqPage />} />
              <Route path={PATHS.WEB_FEATURE} element={<FeaturesPage />} />
              <Route path={PATHS.WEB_SETTINGS} element={<WebSettingsPage />} />
              <Route path={PATHS.WEB_SLIDER} element={<WebSliderPage />} />
              <Route path={PATHS.WEB_PLATFORMS} element={<PlatformsPage />} />
              <Route
                path={PATHS.WEB_AVAILABLE_DEVICES}
                element={<AvailableDevicesPage />}
              />
            </>
          )}

          {/* APPLICATION */}
          <Route path={PATHS.APPLICATION_BASIC} element={<BasicInfoPage />} />
          <Route path={PATHS.APPLICATION_BRANDING} element={<AppBrandingPage />} />
          <Route path={PATHS.APPLICATION_USER_ICONS} element={<UserIconsPage />} />
          <Route path={PATHS.APPLICATION_LANGUAGES} element={<LanguagesPage />} />
          <Route
            path={`${PATHS.APPLICATION_LANGUAGE}:id`}
            element={<TranslationsPage />}
          />
          <Route path={PATHS.MENU_CONFIG} element={<MenuConfigPage />} />

          <Route path={PATHS.APPLICATION_RADIUSES} element={<AppRadiusesPage />} />

          {/* TRANSCODERS */}
          {permissions?.Transcoding && (
            <>
              <Route
                path={PATHS.TRANSCODERS_GROUPS}
                element={<TranscodersGroupsPage />}
              />
              <Route
                path={PATHS.TRANSCODERS_SERVERS}
                element={<TranscodersServersPage />}
              />
              <Route path={PATHS.TRANSCODING} element={<TranscoderTranscodingPage />} />
              <Route path={PATHS.TRANSCODERS_RESOLUTIONS} element={<ResolutionsPage />} />
            </>
          )}

          {/* STREAMER */}
          {permissions?.Streaming && (
            <>
              <Route path={PATHS.STREAM_SERVERS} element={<StreamerServersPage />} />
              <Route path={PATHS.STREAMS} element={<StreamerStreamsPage />} />
            </>
          )}

          {/* CATCH UP */}
          {permissions?.Archive && (
            <>
              <Route path={PATHS.CATCH_UP_SERVERS} element={<CatchUpServersPage />} />
              <Route
                path={PATHS.CATCH_UP_RECORDINGS}
                element={<CatchUpRecordingsPage />}
              />
            </>
          )}

          {permissions?.Import && <Route path={PATHS.IMPORT} element={<ImportPage />} />}

          {/* CLOUD PRICING */}

          {/* {!permissions?.cloud_service && (
            // <Route path={PATHS.CLOUD_SERVICE.CLOUD} element={<PricingLayout />}>
            <Route path={PATHS.CLOUD_SERVICE.CLOUD_PRICING} element={<PricingPage />} />
            // </Route>
          )} */}

          {/* VOD MANAGER */}
          {permissions?.Vod_Manager && (
            <>
              <Route
                path={PATHS.CONTENT_PROVIDER}
                element={<VodContentProvidersPage />}
              />
              <Route path={PATHS.MEDIAS} element={<MediasPage />} />
              <Route path={PATHS.SERIES} element={<SeriesPage />} />
              <Route path={`${PATHS.MEDIA}:id`} element={<MediaPage />} />
              <Route path={`${PATHS.MEDIA_NEW}:?`} element={<MediaPage />} />

              <Route path={`${PATHS.MEDIA_CONTENT}:id`} element={<MediaContentPage />} />
              <Route path={`${PATHS.MEDIA_CONTENT_NEW}`} element={<MediaContentPage />} />

              <Route path={PATHS.GENRES} element={<VodGenresPage />} />
              <Route path={PATHS.CAST} element={<CastPage />} />
              <Route path={PATHS.COLLECTION} element={<VodCollectionsPage />} />
              <Route path={`${PATHS.COLLECTION_PAGE}:id`} element={<CollectionPage />} />
              <Route path={PATHS.MEDIA_TRANSCODING} element={<VodTranscodingPage />} />
            </>
          )}

          {/* SETTINGS */}
          <Route path={PATHS.CDN} element={<CdnPage />} />
          {/* <Route path={PATHS.DOCUMENTS} element={<DocumentsPage />} /> */}
          <Route path={PATHS.SETTINGS} element={<GeneralSettings />} />
          {/* <Route path={PATHS.WEB_HOOKS} element={<WebHooksPage />} /> */}
          <Route path={PATHS.STORE_LINKS} element={<StoreLinksPage />} />
          <Route path={PATHS.APKS} element={<ApksPage />} />
          {/* <Route path={PATHS.VERSIONS} element={<VersionsPage />} /> */}
          <Route path={PATHS.HISTORY} element={<HistoryPage />} />
          <Route path={PATHS.SMTP_CONFIG} element={<SmtpConfigPAge />} />
          <Route path={PATHS.SOCIAL_MEDIA_AUTH} element={<SocialMediaAuth />} />

          <Route
            path={PATHS.SERVICE_NOTIFICATIONS}
            element={<ServiceNotificationsPage />}
          />

          {/* USAGE INFO */}
          {permissions?.promo_code && (
            <Route path={PATHS.PROMO_CODES} element={<PromoCodesPage />} />
          )}

          <Route path={PATHS.USAGE_INFO} element={<UsageInfoPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}
